<template>
  <v-container fluid>
    <v-row>
      <v-subheader>Minha empresa > Sociedade</v-subheader>
      <v-card width="100%">
        <base-header-information
          :title="headerTitle"
          :description="headerDescription"
        >
        </base-header-information>
        <v-row>
          <v-col cols="12">
            <k-ask-change-btn></k-ask-change-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              class="mx-4"
              :data="true"
              :items-per-page-options="-1"
              :items-per-page-text="rowsPerPageText"
              :headers="headers"
              :items="all"
              :disable-sort="$vuetify.breakpoint.smAndDown"
              hide-default-footer
            >
              <template v-slot:item.accountable="{ item }">
                {{ item.accountable ? 'x' : '' }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn :to="{ name: 'Partner', params: { id: item.id } }" icon>
                  <v-icon color="grey darken-2" medium>visibility</v-icon>
                </v-btn>
              </template>

              <template slot="no-data">
                <p class="text-center blue--text py-6 headline">
                  <v-icon color="blue" medium>info</v-icon> Não há contratos.
                </p>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import kAskChangeBtn from '@src/components/widget/AskChangeBtn.vue'

export default {
  name: 'Contract',
  components: {
    kAskChangeBtn,
  },
  data: () => ({
    headers: [
      {
        text: 'Sócio',
        align: 'left',
        value: 'fullName',
      },
      {
        text: 'Responsável pela Empresa',
        value: 'accountable',
        class: 'hidden-sm-and-down',
      },
      {
        text: 'E-mail Proprietário',
        value: 'email',
        class: 'hidden-sm-and-down',
      },
      {
        text: 'Participação % na Sociedade',
        value: 'percentageHolding',
        class: 'hidden-sm-and-down',
      },
      {
        text: '',
        value: 'actions',
      },
    ],
    snackbar: false,
    headerTitle: 'Sociedade',
    headerDescription: `Confira a estrutura societária da sua empresa.
    Para solicitar qualquer alteração nos dados abaixo, você precisará contratar um Serviço Adicional clicando em "Solicitar Mudança".`,
  }),

  computed: {
    ...mapState({
      all: (state) => state.partner.partners,
      tableRowsPerPage: (state) => state.layout.tableRowsPerPage,
      rowsPerPageText: (state) => state.layout.rowsPerPageText,
    }),
  },

  methods: {
    ...mapActions({
      getPartners: 'partner/getAllPartners',
    }),
  },

  mounted() {
    this.getPartners()
  },
}
</script>
<style scoped>
li {
  list-style-type: none;
  padding: 0.5em 0.5em 0.5em 2em;
}

li:before {
  content: attr(data-label);
  text-align: left;
  display: block;
  color: #999;
}
</style>
